<template>
  <div>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col
            cols="6"
            md="3"
            class="d-flex align-center"
          >
            <v-avatar
              size="44"
              color="primary"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiCurrencyUsd }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">
                Funds
              </p>
              <h3 class="text-xl font-weight-semibold">
              <span class="text-xl font-weight-semibold primary--text cursor-pointer"
                    @click="selected_data_deposit">
              {{fund_amount}}
            </span>
              </h3>
            </div>
          </v-col>
          <v-col
            cols="6"
            md="3"
            class="d-flex align-center"
          >
            <v-avatar
              size="44"
              color="error"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiCashMinus }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">
                Transactions
              </p>
              <span class="text-xl font-weight-semibold error--text">
              {{total_withdrews}}
            </span>
            </div>
          </v-col>

          <v-col
            cols="6"
            md="3"
            class="d-flex align-center"
          >
            <v-avatar
              size="44"
              color="info"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiCurrencyUsd }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">
                Current Fund Balance
              </p>
              <h3 class="text-xl font-weight-semibold">
              <span class="text-xl font-weight-semibold info--text">
              {{cash_on_hand}}
            </span>
              </h3>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="fund_data" width="80%" :key="key">
      <v-card
        flat
      >
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">FUND DATA HISTORY</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-data-table dense
                      :headers="headers_teller"
                      :items="teller_fund_data">
          <template v-slot:item="{ item }">
            <tr
            >
              <td>{{ formatPrice(item.amount) }}</td>
              <td>
                {{ formatPrice(item.balance) }}
              </td>
              <td>
                {{ date_format(item.created_at).format('MMMM DD, YYYY HH:mm:ss') }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  // eslint-disable-next-line object-curly-newline
  import {mdiCashMinus, mdiCurrencyUsd, mdiCashPlus} from '@mdi/js'
  import moment from "moment";

  export default {
    props: {
      month_of: Object,
      fund_amount: String,
      cash_on_hand: String,
      total_deposits: String,
      total_withdrews: String,
      teller_fund_data: Array,
    },
    setup() {
      return {
        fund_data: false,
        key: 0,
        // icons
        icons: {
          mdiCashPlus,
          mdiCashMinus,
          mdiCurrencyUsd,
        },
        headers_teller: [
          {text: 'FUND', value: 'id', sortable: false},
          {text: 'CURRENT BALANCE', value: 'date_of_deposit', sortable: false},
          {text: 'DATE', value: 'amount', sortable: false},],
      }
    },
    methods: {
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },
      selected_data_deposit() {
        this.key++
        this.fund_data = true
      },
    },
  }
</script>
