<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="12">
        <teller-dashboard-funds-card
          :fund_amount="fund_amount"
          :cash_on_hand="cash_on_hand"
          :total_deposits="total_deposits"
          :total_withdrews="total_withdrews"
          :teller_fund_data="teller_fund_data"
        ></teller-dashboard-funds-card>
      </v-col>
      <v-col
        v-if="position==='CASHIER'"
        cols="12"
        md="12">
        <teller-dashboard-card-deposit-and-withdraw
          :deposit="deposit"
          :withdraw="withdraw"
          :total_deposits="total_deposits"
          :total_withdrews="total_withdrews"
        ></teller-dashboard-card-deposit-and-withdraw>
      </v-col>
      <v-col
        v-else
        cols="12"
        md="12"
      >
        <teller-dashboard-card-deposit-and-withdraw-view
          :deposit="deposit"
          :withdraw="withdraw"></teller-dashboard-card-deposit-and-withdraw-view>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  // eslint-disable-next-line object-curly-newline
  import {mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline} from '@mdi/js'

  // demos

  import TellerDashboardCardDepositAndWithdraw
    from './teller_interface/TellerDashboardCardDepositAndWithdraw'
  import TellerDashboardFundsCard from './teller_interface/TellerDashboardFundsCard'
  import TellerDashboardCardDepositAndWithdrawView
    from './teller_interface/TellerCardDepositAndWithdrawBranch'
  import {mapActions, mapGetters} from "vuex";

  export default {
    components: {
      TellerDashboardFundsCard,
      TellerDashboardCardDepositAndWithdraw,
      TellerDashboardCardDepositAndWithdrawView,
    },
    computed: {
      ...mapGetters('authentication', ['branch_id', 'position', 'user_id', 'month_of']),
    },
    data() {
      return {
        data_table_loading: false,
        deposit: [],
        withdraw: [],
        totalEarnings: '0',
        totalEarning: [],
        teller_fund_data: [],
        listOfSales: [],
        total_deposits: '0',
        total_withdrews: '0',
        fund_amount: '0',
        cash_on_hand: '0',
      }
    },
    methods: {
      ...mapActions('fund_liquidation', ['sporo_daily_transaction_data', 'sporo_monthly_transaction_data']),
    },
    mounted() {
      this.data_table_loading = true
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        if (this.position === 'CASHIER') {
          const data = new FormData()
          data.append('branch_id', this.branch_id);
          data.append('maker_id', this.user_id);
          this.sporo_daily_transaction_data(data)
            .then(response => {
              this.withdraw = response.data[0].withdraw
              this.total_withdrews = response.data[0].total_withdrews
              this.fund_amount = response.data[0].fund
              this.teller_fund_data = response.data[0].teller_fund_data
              this.cash_on_hand = response.data[0].cash_on_hand
              this.data_table_loading = false
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
              this.data_table_loading = false
            })
        } else {
          const data = new FormData()
          data.append('branch_id', this.branch_id);
          data.append('month_of', this.month_of)

          this.sporo_monthly_transaction_data(data)
            .then(response => {
              this.deposit = response.data[0].deposit
              this.withdraw = response.data[0].withdraw
              this.fund_amount = response.data[0].fund
              this.teller_fund_data = response.data[0].fund_data
              this.total_withdrews = response.data[0].total_withdrews
              this.cash_on_hand = response.data[0].cash_on_hand
              this.data_table_loading = false
            })
            .catch(error => {
              this.data_table_loading = false
            })
        }
      }, 200)

    },
    setup() {
      const totalProfit = {
        statTitle: 'Total Profit',
        icon: mdiPoll,
        color: 'success',
        subtitle: 'Weekly Project',
        statistics: '$25.6k',
        change: '+42%',
      }

      const totalSales = {
        statTitle: 'Refunds',
        icon: mdiCurrencyUsd,
        color: 'secondary',
        subtitle: 'Past Month',
        statistics: '$78',
        change: '-15%',
      }

      // vertical card options
      const newProject = {
        statTitle: 'New Project',
        icon: mdiLabelVariantOutline,
        color: 'primary',
        subtitle: 'Yearly Project',
        statistics: '862',
        change: '-18%',
      }

      const salesQueries = {
        statTitle: 'Sales Quries',
        icon: mdiHelpCircleOutline,
        color: 'warning',
        subtitle: 'Last week',
        statistics: '15',
        change: '-18%',
      }

      return {
        totalProfit,
        totalSales,
        newProject,
        salesQueries,
      }
    },
  }
</script>
