<template>
  <div>
    <v-card>
      <div class="d-flex flex-sm-row flex-column">
        <div class="flex-grow-1">
          <v-card-title>
            <span class="me-3">Sporo Transactions</span>
            <v-spacer></v-spacer>
          </v-card-title>

          <v-card-text>
            <v-list>
              <v-list-item
                v-for="(data,index) in withdraw"
                :key="data.date"
                :class="`d-flex px-0 ${index > 0 ? 'mt-0':''}`"
              >

                <div class="d-flex align-center flex-grow-1 flex-wrap">
                  <div class="me-auto pe-2">
                    <h4 class="font-weight-semibold">
                      {{ data.date }}
                    </h4>
                  </div>

                  <span class="font-weight-semibold error--text cursor-pointer"
                        @click="selected_data_withdrawal(withdraw.map(function(x) {return x.date; }).indexOf(data.date))">{{ data.amount }}</span>
                </div>
              </v-list-item>
            </v-list>
          </v-card-text>
        </div>
      </div>
    </v-card>
    <v-dialog v-model="teller_transaction_data" width="80%">
      <v-card
        flat
      >
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">SPORO TRANSACTIONS DATA</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">{{title}}</h4>
          </v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <span class="headline font-weight-light white--text"
                v-if="teller_data.length>0">
                                                Print
                                                <v-icon
                                                  class="mr-2"
                                                  color="warning"
                                                  @click="print_selected_deposit_slip_data()"
                                                >
                                                {{icons.mdiPrinter}}
                                                </v-icon>
                                            </span>
        </v-toolbar>
        <v-data-table dense
                      :headers="headers_teller"
                      :items="teller_data">
          <template v-slot:item="{ item }">
            <tr
            >
              <td>{{ item.particulars.particulars }}
              </td>
              <td>
                {{ item.reference_no
                }}
              </td>
              <td>
                {{ date_format(item.created_at).format('HH:mm:ss')}}
              </td>
              <td>
                {{ formatPrice(item.total) }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

  import {mapActions, mapGetters} from "vuex";
  import {mdiPrinter, mdiDelete, mdiEyeOutline} from '@mdi/js'

  import {
    mdiAccountCashOutline,
    mdiAccountOutline
  } from "@mdi/js";
  import moment from "moment";

  export default {
    setup() {
      return {
        icons: {
          mdiPrinter,
          mdiDelete,
          mdiEyeOutline,
        },
      }
    },
    data() {
      return {
        title: '',
        total: '',
        key: 0,
        savings_date: '',
        headers_teller: [
          {text: 'PARTICULARS', value: 'id', sortable: false},
          {text: 'TRANS. REF. #', value: 'date_of_deposit', sortable: false},
          {text: 'TIME', value: 'amount', sortable: false},
          {text: 'AMOUNT', value: 'time_of_deposit', sortable: false},],
        teller_transaction_data: false,
        remittances_dialog: false,
        teller_data: [],
      }
    },
    props: {
      deposit: Array,
      withdraw: Array,
    },
    computed: {
      ...mapGetters('authentication', ['position', 'branch', 'branch_id', 'branch_address', 'branch_contact_no', 'branch_selected']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      date_format(value) {
        return moment(value)
      },
      selected_data_withdrawal(index) {
        if (this.position === 'BRANCH MANAGER' || this.position === 'ADMIN' || this.position === 'ADMIN SPECIAl') {
          this.title = 'TRANSACTION as of ' + this.withdraw[index].date
          this.teller_transaction_data = true
          this.total = this.withdraw[index].amount
          this.teller_data = this.withdraw[index].withdrawals_data
        }
      },
      print_selected_deposit_slip_data() {
        var imgData = this.company_logo
        var payments_array = []
        var date = this.title
        var total_net = this.total
        payments_array.push(
          [
            {text: 'PARTICULARS', alignment: 'center', style: 'label'},
            {text: 'TRANS. REF. #', alignment: 'center', style: 'label'},
            {text: 'TIME', alignment: 'center', style: 'label'},
            {text: 'AMOUNT', alignment: 'center', style: 'label'},
          ]
        )
        if (this.teller_data.length > 0) {
          this.teller_data.forEach(function (item, index, payment) {
            payments_array.push(
              [
                {
                  text: item.particulars.particulars,
                  alignment: 'center'
                },
                {
                  text: item.reference_no,
                  alignment: 'center'
                },
                {
                  text: moment(item.created_at).format('HH:mm:ss'),
                  alignment: 'center'
                },
                {
                  text: (item.total / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left'
                },
              ]
            )
            if (Object.is(payment.length - 1, index)) {
              // execute last item logic
              payments_array.push(
                [
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {
                    text: 'Total:',
                    alignment: 'right',
                    border: [false, false, false, false],
                  },
                  {
                    text: total_net,
                    alignment: 'left',
                    border: [false, false, false, false],
                  },
                ]
              )
            }
          })
        } else {
          payments_array.push(
            [
              '----',
              '----',
              '----',
              '----',
            ]
          )
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE SAVINGS & CREDIT COOPERATIVE',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'C.D.A. REG. No. 9520-1011000000048747 CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'TELLER TRANSACTIONS DATA', style: 'title'},
            '================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            {
              text: 'Branch: ' + this.branch_selected === 'NA' ? this.branch : this.branch_selected,
              style: 'main_info'
            },
            {text: date, style: 'main_info'},
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: [160, 160, 80, 100],
                body: payments_array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Prepared By: ', style: 'aa_top_margin'},
                    {text: 'Received By:', style: 'cashier_top_margin'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'aa_for'},
                    {text: '', style: 'cashier_for'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'aa_line'},
                    {text: '_________________________________', style: 'cashier_line'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: 'Collector', style: 'aa_placeholder'},
                    {text: 'Cashier', style: 'cashier_placeholder'},
                    {text: ''},
                  ]
                },
              ]
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Verified By: ', style: 'cs_top_margin'},
                    {text: 'Verified By: ', style: 'bm_top_margin'},
                    {text: 'Audited By: ', style: 'au_top_margin'},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'cs_for'},
                    {text: '', style: 'bm_for'},
                    {text: '', style: 'au_for'},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'cs_line'},
                    {text: '_________________________________', style: 'bm_line'},
                    {text: '_________________________________', style: 'au_line'},
                  ]
                },
                {
                  columns: [
                    {text: 'Collection Supervisor', style: 'cs_placeholder'},
                    {text: 'Branch Manager', style: 'bm_placeholder'},
                    {text: 'Auditor', style: 'au_placeholder'},
                  ]
                },
              ]
            },

          ],
          footer: {
            columns: [
              {
                text: 'Generated with Goodlife Savings & Credit Cooperative System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 12
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 9,
            },

            aa_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            bm_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cs_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            aa_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_placeholder: {
              margin: [15, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_for: {
              margin: [0, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_placeholder: {
              margin: [26, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_placeholder: {
              margin: [5, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            au_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
    }
  }
</script>
